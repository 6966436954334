<template>
  <nav class="content-fluid col-10">
    <div class="container">
      <ul class="nav nav-pills nav-fill mb-3" id="menu" role="tablist">
          <div class="d-flex flex-column">
            <div class="d-flex">
              <li class="nav-item p-2" v-if="hasNorthStar">
              <a class="nav-link active" id="northstar-tab" data-toggle="tab" href="#northstar" role="tab" @click="$emit('change-list', 'NorthStar'), NorthStarIsActive(true)"
                aria-controls="northstar" aria-selected="true" :class="northStarIsActive ? 'activeLink' : ''"><i class="fa fa-regular fa-compass fa-lg p-2"></i>NorthStar</a>
            </li>
            <li class="nav-item p-2" @click="$emit('change-list', 'HealthScore'), NorthStarIsActive(false)" v-if="hasHealthScore">
              <a class="nav-link active" id="healthscore-tab" data-toggle="tab" href="#healthscore" role="tab"
                aria-controls="healthscore" aria-selected="true" :class="!northStarIsActive ? 'activeLink' : ''"><i class="fa fa-heart fa-lg p-2"></i>Health Score</a>
            </li>
            </div>
            <div v-if="hasNorthStar && hasHealthScore" :class="northStarIsActive ? 'line-indicator-left' : 'line-indicator-right'"></div>
        </div>
        <div class="content-period">
          <span class="period"><i class="fa fa-regular fa-calendar"></i></span>
       <vuejsDatepicker placeholder=" Filtrar por período" :language="ptBR" :bootstrap-styling="true" :minimumView="'week'" :maximumView="'month'" :initialView="'month'" @selected="onDateSelected"></vuejsDatepicker>
        </div>
      </ul>
    </div>
  </nav>
</template>
<script>
import vuejsDatepicker from 'vuejs-datepicker';
import northStartCompanies from "../../../../../services/northStar"
import {ptBR} from 'vuejs-datepicker/dist/locale'

export default {
  components:{
    vuejsDatepicker
},
data(){
  return {
    nortStarService : new northStartCompanies(),
      selectedDate: null,
      ptBR: ptBR,
      northStarIsActive: true,
      hasHealthScore: true,
      hasNorthStar: true
    };
},  
  methods: {
    NorthStarIsActive(data){
      this.northStarIsActive = data
    },
    onDateSelected(selectedDate) {    
      if(this.northStarIsActive){
        this.fetchDocumentsForWeek(selectedDate);
      }
      else {
        this.$emit('filter-health-period', selectedDate)
      }  
    },
    fetchDocumentsForWeek(selectedDate) {
      const startOfLastWeek = new Date(selectedDate);
      startOfLastWeek.setDate(selectedDate.getDate() - 1);
      const endOfLastWeek = new Date(selectedDate);
      endOfLastWeek.setDate(selectedDate.getDate() + 5);
      const formattedStartDate = this.formatDate(startOfLastWeek);
      const formattedEndDate = this.formatDate(endOfLastWeek);
      this.$emit('filter-period', formattedStartDate, formattedEndDate)

    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    startPopover() {
      $('[data-toggle="popover"]').popover('show');
      setTimeout(() => {
        $('[data-toggle="popover"]').popover('hide');
      }, 3000);
    }
  }
}
</script>
<style scoped>
.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
}

.nav-link{
  border-bottom: transparent solid 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #002D6E;
  font-weight: 500;
  background-color: #ffffffd8;
}

.content-period{
  display: flex;
  justify-content: center;
  align-items: center;
}
span.period{
margin: 1rem;
color: #007db3df;
}
.period{
  margin: 100px;
}
.activeLink>i{
  color: #007db3df;
  transition: all ease-in-out 0.3s;
}
.line-indicator-left{
  background-color: #007db3df;
  width: 50%;
  height: 3px;
  position: relative;
  bottom: 0;
  left: 0;
  transition: all ease-in-out 0.3s;
}
.line-indicator-right{
  background-color: #007db3df;
  width: 50%;
  height: 3px;
  position: relative;
  bottom: 0;
  left: 50%;
  transition: all ease-in-out 0.3s;
}
</style>