import axios from "axios";
export default class HealthScoreService {
  megasac_api = axios.create({
    baseURL: process.env.VUE_APP_URL_TALLOS_ADMIN_API,
    headers: {
      "Content-Type": "application/json",
    },
  });

  async getCompanyScore(company) {
    try{
      let result = await this.megasac_api.get(
        `/healthScore/getScore/${company}`
      );
      return result;

    }catch(error){
      return error
    }
  }

  async getCompanyScoreByPeriod(company, period) {
    try{
      let result = await this.megasac_api.get(
        `/healthScore/getScoreByPeriod/${company}/${period}`
      );
      return result.data;

    }catch(error){
      throw new Error('Erro ao trazer health score da empresa')
    }
  }
  
}