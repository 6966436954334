<template>
<div class="card w-100 h-100">
  <div class="card-body">
    <table class="table">
    <thead>
      <tr>
        <th>Tipo</th>
        <th>Meta</th>
        <th>Realizado</th>
        <th>Detalhamento</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="font-weight-normal">Status de pagamento de mensalidade</td>
        <td class="font-weight-light text-secondary">20%</td>
        <td class="font-weight-light text-secondary">{{ getPaymentPercent }}</td>
        <td class="font-weight-light text-secondary text-capitalize"> {{ getPaymentValue }} </td>
      </tr>
      <tr>
        <td class="font-weight-normal">North Star</td>
        <td class="font-weight-light text-secondary">30%</td>
        <td class="font-weight-light text-secondary">{{ getNorthStarPercent }}</td>
        <td class="font-weight-light text-secondary">{{ getNorthStarStatus }} <a href="#" @click="$emit('changeToNorthStar')"><u>Ver detalhes</u></a></td>
      </tr>
      <tr>
        <td class="font-weight-normal" >Tickets com sla atrasado</td>
        <td class="font-weight-light text-secondary">20%</td>
        <td class="font-weight-light text-secondary">{{ getTicketsPercent }}</td>
        <td class="font-weight-light text-secondary d-flex justify-content-center">
            <p class="mr-3">Tickets</p> <strong class="text-danger mr-3">{{ getTicketsNumber }} tickets </strong>
            <ul class="list-unstyled d-flex flex-column align-items-start" v-for="ticket in healthScoreData.tickets">
                <li>{{ ticket }}</li>
            </ul>
        </td>
      </tr>
      <tr>
        <td class="font-weight-normal">NPS</td>
        <td class="font-weight-light text-secondary">15%</td>
        <td class="font-weight-light text-secondary">{{ getNPSPercent }}</td>
        <td class="font-weight-light text-secondary">{{ getNPSStatus }}</td>
      </tr>
      <tr>
        <td class="font-weight-normal">CSAT</td>
        <td class="font-weight-light text-secondary">15%</td>
        <td class="font-weight-light text-secondary">{{ getCSATPercent }}</td>
        <td class="font-weight-light text-secondary">Nota {{ healthScoreData.csat }}</td>
      </tr>
      <tr>
        <td>TOTAL</td>
        <td>100%</td>
        <td><strong :class="getHealthScoreColor">{{ getScorePayment }}%</strong></td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
</template>
<script>
export default {
  props: {
    healthScoreData: {
      type: Object,
    },
    proposalData: {
      type: Object,
    }
  },
  computed: {
    getPaymentPercent(){
      return this.proposalData?.subscription?.sale_item?.name ? '20%' : '0%'
    },
    getPaymentValue(){
      return this.proposalData?.subscription?.sale_item?.name ? this.proposalData.subscription.sale_item.name : 'Sem informações'
    },
    getScorePayment(){
      return this.proposalData?.subscription?.sale_item?.name ? this.healthScoreData.score: this.healthScoreData.score - 20
    },
    getNorthStarPercent(){
      return this.healthScoreData?.lastNorthStar ? '30%' : '0%'
    },
    getNorthStarStatus(){
      return this.healthScoreData?.lastNorthStar ? 'Sim' : 'Não'
    },
    getNPSPercent(){
      if(this.healthScoreData?.nps){
        return this.healthScoreData?.nps > 8 ? '15%' : '0%'
      }
      else {
        return '15%'
      }
    },
    getNPSStatus(){
      if(this.healthScoreData?.nps < 7){
        return 'Detrator'
      }
      else if(this.healthScoreData?.nps > 6 && this.healthScoreData?.nps < 9){
        return 'Neutro'
      }
      else {
        return 'Promotor'
      }
    },
    getCSATPercent(){
      if(this.healthScoreData?.csat){
        return this.healthScoreData?.csat > 8 ? '15%' : '0%'
      }
      else {
        return '15%'
      }
    },
    getHealthScoreColor(){
      if(this.getScorePayment >= 80){
        return 'text-success'
      }
      else if(this.getScorePayment >= 50 && this.getScorePayment < 80){
        return 'text-warning'
      }
      else {
        return 'text-danger'
      }
    },
    getTicketsNumber(){
      return this.healthScoreData?.tickets?.length ? this.healthScoreData.tickets.length : 0
    },
    getTicketsPercent(){
      if(!this.healthScoreData?.tickets || this.healthScoreData?.tickets?.length < 1){
        return '20%'
      }
     else {
      this.healthScoreData?.tickets.some((ticket) => ticket.overdue === true) ? '0%' : '20%'
     }
    }
  },
}
</script>
<style>
</style>