<template>
  <div class="content-wrapper">
    <div class="d-flex flex-column align-items-center" v-if="loadingError">
      <img width="250px" height="250px" src="../../../.././assets/images/Tallos404.svg" alt="">
      <h1 class="text-primary">HealthScore e NorthStar não encontrados</h1>
    </div>
    <div class="container col-12 mt-2 p-5" v-else>

      <div class="company" v-if="hasCompany">
        <section>
          <div class="company-name container-fluid">
            <div class="col-sm-12 d-flex align-items-center">
              <span class>
                <p>{{ company.company_name }}</p>
              </span>
              <span>
                <p class="badge badge-pill badge-primary" style="background-color: #007DB3;">
                  {{ typePlan[company.account_type] }}
                </p>
              </span>
            </div>
          </div>
          <div class="infos">
            <div class="info">
              <p>
                CNPJ:<strong><span>{{ company.cnpj }}</span></strong>
              </p>
            </div>
            <div class="info">
              <p>
                Email:<strong><span>{{ company.email }}</span></strong>
              </p>
            </div>
            <div class="info">
              <p>
                Contato:<strong><span>{{ company.focal_contact }}</span></strong>
              </p>
            </div>
            <div class="info">
              <p>
                Telefone:<strong><span>{{ company.phone }}</span></strong>
              </p>
            </div>
          </div>
          <div class="d-flex w-100">
            <div :class="[companyNorthstar, 'card', 'mt-4', 'mr-4']">
              <div class="status-content">
                <div>
                  <span><i class="fa fa-regular fa-compass fa-lg"></i></span>
                </div>
                <div>
                  <strong>{{ status[company.northStar] }}</strong>
                </div>
              </div>
              <div class="north-star">
                <span>NorthStar</span>
              </div>
            </div>
            <div class="card mt-4">
              <div class="status-content">
                <div>
                  <span><i class="fa fa-regular fa-heart fa-lg"></i></span>
                </div>
                <div :class="getHealthScoreColor">
                  <strong>{{ scoreNumber }}</strong>
                </div>
              </div>
              <div class="north-star">
                <span>Health Score</span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="navigation-bar">
        <Navigation @filter-period="searchByPeriod" @filter-health-period="searchHealthByPeriod" @change-list="changeList"
          ref="navigation" />
      </div>
      <section class="company-north-star">
        <List v-if="listSelected === 'NorthStar'" :company="company" :integrationDataEssential="integrationDataEssential"
          :integrationDataAny="integrationDataAny" :efficiencyDatasAny="efficiencyDatasAny"
          :attendanceDatasAny="attendanceDatasAny" :scaleDatasAdvanced="scaleDatasAdvanced"
          :scaleDatasProfessional="scaleDatasProfessional" :successDatasEssential="successDatasEssential"
          :successDatasAdvanced="successDatasAdvanced" :successDatasProfessional="successDatasProfessional" />
        <HealthScoreList v-if="listSelected === 'HealthScore'" :healthScoreData="healthScoreData" :proposalData="proposal"
          @changeToNorthStar="activeNorthStarByLink()" />
      </section>
    </div>
  </div>
</template>
<script>
import northStartCompanies from "../../../../services/northStar";
import { TitlePage } from "../../../shared/components/title-page";
import List from "../northstar/list/index.vue";
import HealthScoreList from "../northstar/list/healthScoreTable.vue"
import Navigation from "../northstar/navbar";
import HealthScoreService from  '../../../../services/health-score-service'
export default {
  components: {
    TitlePage,
    List,
    Navigation,
    HealthScoreList
  },

  data() {
    return {
      loadingError: false,
      hasCompany: true,
      proposal: {},
      listSelected: "NorthStar",
      titlePage: "Monitorar Indicadores",
      iconClass: "fa fa-star",
      status: {
        false: "NÃO",
        true: "SIM",
      },
      typePlan: {
        essential: "ESSENCIAL",
        advanced: "AVANÇADO",
        professional: "PROFISSIONAL",
        basic: "BÁSICO",
      },
      company: {},
      page: 1,
      perPage: 1,
      cnpj: "",
      name: "",
      integrationDataEssential: [
        { integration: "Whatsapp"},
        { integration: "Instagram" },
      ],
      integrationDataAny: [
        { integration: "Whatsapp"},
        { integration: "Instagram" },
        { integration: "Widget" }
      ],
      efficiencyDatasAny:[
        {efficiency: "Mensagens Rápidas Cadastradas"},
        {efficiency: "Variáveis Cadastradas"},

      ],
      attendanceDatasAny: [
        {attendance: "Setores"},
        {attendance: "Tabulações"},
        {attendance: "Etiquetas"},
      ],
      scaleDatasAdvanced: [
      {scale: "1 fluxo ou mais cadastrado e ativo na plataforma"}
      ],
      scaleDatasProfessional: [
      {scale: "1 fluxo ou mais cadastrado e ativo na plataforma"},
      {scale: "Uso de requisições externas no fluxo do chatbot"},
      {scale: "Uso de campanhas de lista de transmissão nos últimos 30 dias"},
      ],
      successDatasEssential:[
      {success: "Uma nova template cadastrada nos últimos 30 dias"},
      {success: "80% da base de usuários operadores ativos (login nos últimos 7 dias)"},
      {success: "50% da base de usuários administradores ativos (login nos últimos 7 dias)"},
      {success: "Acesso à tela de monitoramento no mínimo uma vez nos 7 últimos dias"},
      {success: "Acesso à tela de dashboard no mínimo uma vez nos 7 últimos dias"},
      {success: "Troca maior que 7000 interações em mensagens nos últimos 7 dias."},
      {success: "Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias"},
      {success: "Crescimento da base de contatos de 1% nos últimos 7 dias"}
      ],
      successDatasAdvanced:[
      {success: "Uma nova template cadastrada nos últimos 21 dias"},
      {success: "80% da base de usuários operadores ativos (login nos últimos 7 dias)"},
      {success: "50% da base de usuários administradores ativos (login nos últimos 7 dias)"},
      {success: "Acesso à tela de monitoramento no mínimo uma vez nos 7 últimos dias"},
      {success: "Acesso à tela de dashboard no mínimo uma vez nos 7 últimos dias"},
      {success: "Troca maior que 14000 interações em mensagens nos últimos 7 dias."},
      {success: "Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias"},
      {success: "Crescimento da base de contatos de 2% nos últimos 7 dias"}
      ],
      successDatasProfessional:[
      {success: "Uma nova template cadastrada nos últimos 14 dias"},
      {success: "80% da base de usuários operadores ativos (login nos últimos 7 dias)"},
      {success: "50% da base de usuários administradores ativos (login nos últimos 7 dias)"},
      {success: "Acesso à tela de monitoramento no mínimo uma vez nos 3 últimos dias"},
      {success: "Acesso à tela de dashboard no mínimo uma vez nos 3 últimos dias"},
      {success: "Troca maior que 21000 interações em mensagens nos últimos 7 dias. Esse valor será acrescentado 1050 interações nos últimos 7 dias por usuário operador cadastrado e ativo na empresa."},
      {success: "Ao menos 50% das conversas iniciadas pelos operadores foram finalizadas nos últimos 7 dias"},
      {success: "Crescimento da base de contatos de 3% nos últimos 7 dias"}
      ],
      startDate: null,
      endDate: null,
      nortStarService: new northStartCompanies(),
      healthScoreService: new HealthScoreService(),
      healthScoreData: {},
    };
  },
  methods: { 
    async searchByPeriod(formattedStartDate, formattedEndDate) {
      let company_id = this.company.company_id
      this.startDate = formattedStartDate;
      this.endDate = formattedEndDate;
        let query = {
          company_id,
          formattedStartDate,
          formattedEndDate
        }
        try {
          let response = await this.nortStarService.getNorthStarsByPeriod(query)
          if (!response) {
            this.$toasted.global.default({ msg: 'Não há registro para esse período.' })
          } else {
            this.company = {};
            this.company = response;
            return response;
          }
        } catch {
          return undefined
        }
    },
    async searchHealthByPeriod(selectedDate){
      let company_id = this.company.company_id
      try {
          let response = await this.healthScoreService.getCompanyScoreByPeriod(company_id, selectedDate)
          if (!response) {
            this.$toasted.global.default({ msg: 'Não há registro para esse período.' })
          } else {
            this.healthScoreData = response;
            return response;
          }
        }
        catch {
          return undefined
        }
    },
    async getCompanyNorthStatus() {
      let response = await this.nortStarService.getCompanyStatus(
        this.$route.params.id
      ).then((res) => {
        if(!res.data){
          this.threatNorthStarError() 
        }
        return res
      })
      .catch((err) => {
        this.threatNorthStarError() 
      })
      this.company = response?.data;
      this.getProposal()
      return response;
    },
    async getHealthScore() {
      let result = await this.healthScoreService.getCompanyScore(this.$route.params.id)
      if (!result.data || ![200, 201].includes(result?.status)) {
        this.$refs.navigation.hasHealthScore = false
        this.$toasted.global.defaultError({ msg: 'Erro ao buscar health score.' })
      } else {
        this.healthScoreData = result.data
      }
    },
    back() {
      this.$router.push({ name: "companies" });
    },
    changeList(data){
      this.listSelected = data
    },
    activeNorthStarByLink(){
      this.listSelected = 'NorthStar'
      this.$refs.navigation.northStarIsActive = true
    },
    getProposal() {
			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;
			this.$tallos_admin_api
				.get(`proposals/getProposalByCNPJ?cnpj=${this.company?.cnpj}`)
				.then(res => {
          this.proposal = res.data
				})
		},
    checkHasData(){
      if(!this.$refs.navigation.hasNorthStar && !this.$refs.navigation.hasHealthScore){
        this.loadingError = true
      }
    },
    threatNorthStarError() {
      this.$refs.navigation.northStarIsActive = false
      this.$refs.navigation.hasNorthStar = false
      this.listSelected = 'HealthScore'
      this.$toasted.global.defaultError({ msg: 'Erro ao buscar o northstar.' })
      this.hasCompany = false
    }
  },
  computed: {
    companyNorthstar() {
      return this.company.northStar === true ? "is--true" : "is--false";
    },
    scoreNumber(){
      if(this.healthScoreData?.score){
        return this.proposal?.subscription?.sale_item?.name ? this.healthScoreData.score: this.healthScoreData.score - 20
      }
      else {
        return 0
      }
    },
    getHealthScoreColor(){
      if(this.scoreNumber >= 80){
        return 'text-success'
      }
      else if(this.scoreNumber >= 50 && this.scoreNumber < 80){
        return 'text-warning'
      }
      else {
        return 'text-danger'
      }
    },

  },
  async created() {
    await this.getCompanyNorthStatus();
    await this.getHealthScore();
    this.checkHasData()
  },
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white !important;
}

.company {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  padding: 1.2rem;
  width: 100%;
  margin-top: 2rem;
  justify-content: flex-start;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.company-north-star {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  padding: 1.2rem;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.company-name {
  margin-top: 5%;
  font-weight: 600;
  color: rgb(28, 6, 109);
  letter-spacing: 1px;
  font-size: 1.5rem;
}

.card {
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  height: 4.8rem;
  width: 8rem;
  font-size: 1rem;
  font-weight: 600;
}

.navigation-bar{
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  padding: 1.2rem;
  width: 100%;
  margin-top: 2rem;
  justify-content: flex-start;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.north-star {
  border: none;
  font-weight: 300;
  font-size: 0.7rem;
  color: #222222;
}

.is--true {
  text-align: center;
  color: #002d6e;
  background-color: #007db363;
}
section {
  border: none;
  max-width: 100%;
  max-height: 100%;
}
.is--false {
  text-align: center;
  color: #e5000c;
  background-color: #e1b6b18f;
}

.status-content {
  border: none;
  display: flex;
  background-color: transparent;
}

.infos {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.info {
  padding: 0.5rem;
  font-size: 0.9rem;
}

span {
  margin: 0.8rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

th {
  background-color: #f2f2f2;
}

@media (max-width: 600px) {
  table {
    display: block;
  }

  thead {
    display: none;
  }

  tr {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
  }

  td:before {
    font-weight: bold;
    margin-right: 5px;
    content: attr(data-label);
  }
}
</style>
